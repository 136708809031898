import React, { Fragment } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";

import styles from "./best-banks.module.scss";

/**
 * This is the component to render a section for best banks.
 *
 * @param {Object} props - The object containing props.
 * @param {string} props.imagePosition - The best bank image position accepted options: "left", "right".
 * @param {string} props.linearGradientBg - Whether you show the gradient background class for the section.
 * @param {string} props.sectionClass - The section container of all classes like "bg-light".
 * @param {string} props.containerClass - The div inside the section that contains all the content classes like "border-bottom".
 * @param {string} props.columnClass - The div classes that contains the text and content besides the best bank image like "border-left".
 * @param {string} props.imageColumnClass - The image div container classes like "border-right".
 * @param {boolean} props.imageClass - The best bank image component classes.
 * @param {boolean} props.useLink - true or false, Make the image a link to "/about-us/best-banks".
 * @param {string} props.useFixedImage - Uses fluid image if false.
 * @param {ReactNode[]} props.components - Array of React nodes to render as content like title, descriptions and more from top to bottom, for example: [<></>, <></>].
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const BestBanks = ({
  imagePosition = "left",
  linearGradientBg = false,
  sectionClass = null,
  containerClass = null,
  columnClass = "col-sm-12 col-md-8 col-lg-9 my-auto",
  imageColumnClass = "col-sm-12 col-md-4 col-lg-3",
  imageClass = null,
  useLink = true,
  useFixedImage = false,
  components = []
}) => {
  // image is 500x643 px
  // const classNameFromProps = imageClass;

  const imageData = useStaticQuery(graphql`
    {
      bestBanksImage: file(relativePath: { eq: "newsweek-3yr-lockup-600.png" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 380
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const BestBanksImage = () => {
    return useFixedImage ? (
      <StaticImage
        src="../../images/newsweek-3yr-lockup-600.png"
        alt="WaFd Bank voted Best Bank by Newsweek"
        placeholder="blurred"
        loading="eager"
        quality="100"
        width={360}
        height={225}
        imgClassName={imageClass}
      />
    ) : (
      <GatsbyImage
        image={imageData.bestBanksImage.childImageSharp.gatsbyImageData}
        alt="WaFd Bank voted Best Bank by Newsweek"
      />
    );
  };

  const ImgCol = () => (
    <div className={`${imageColumnClass}`}>
      <div className="text-center mx-auto">
        {useLink ? (
          <Link to="/about-us/best-bank" id="clickable-best-banks-image">
            <BestBanksImage />
            <span className="sr-only">America's Best Bank 2021, 2022 and 2023 - Newsweek</span>
          </Link>
        ) : (
          <BestBanksImage />
        )}
      </div>
    </div>
  );

  return (
    <section id="best-banks-section" className={`${sectionClass} ${linearGradientBg ? `${styles.bestBanksBg}` : ""}`}>
      <div className={`container ${containerClass}`}>
        <div className="row">
          {imagePosition && imagePosition === "left" && <ImgCol />}
          <div className={`${columnClass}`}>
            {components &&
              components.map((component, index) => {
                return <Fragment key={index}>{component}</Fragment>;
              })}
          </div>
          {imagePosition && imagePosition === "right" && <ImgCol />}
        </div>
      </div>
    </section>
  );
};
export default BestBanks;

BestBanks.propTypes = {
  imagePosition: PropTypes.string,
  linearGradientBg: PropTypes.bool,
  sectionClass: PropTypes.string,
  containerClass: PropTypes.string,
  columnClass: PropTypes.string,
  imageColumnClass: PropTypes.string,
  imageClass: PropTypes.string,
  useLink: PropTypes.bool,
  useFixedImage: PropTypes.bool,
  components: PropTypes.arrayOf(PropTypes.node).isRequired
};
